import React, { Fragment, useContext, useEffect, useState } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import { isBrowser } from "../../../../../services/general"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"

import { formSchema } from "../../../utils/formSchema"
import { AppContext } from "../../../../../context/AppContext"
import { RefillsContext } from "../../RefillsContext/RefillsContext"
import { parseFormField } from "../../../../../services/airtable"
import { generateFormField } from "../../../../Elements/Form/services/form"
import { generateInitialValues } from "../../../../../services/context"
import { toCamelCase } from "../../../../../services/general"
import { heightAndWeightOptions } from "../../../../Elements/utils/heightAndWeightOptions"
import { getBackPage } from "../../services/getPreviousPage"

const TeleconsultForm = ({ pageContext }) => {
  const { refillsState, refillsDispatch } = useContext(RefillsContext)

  // redirect to personal details if email is empty
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      refillsState?.updatedPrescription?.email?.length === 0
    ) {
      navigate("/medensure/refills/")
    }
  }, [refillsState?.updatedPrescription?.email])
  let formFields = pageContext.formFields

  formFields = formFields.map((field) => {
    if (field?.type === "checkbox") {
      field.onChange = (event, option, setFieldValue, isChecked) => {
        if (!isChecked) {
          if (
            field?.followUpQuestions.length > 0 &&
            (option === "Other chronic condition(s)" || option === "Others")
          ) {
            setFieldValue(field?.followUpQuestions[0], "")
            setTimeout(() => {
              setFieldValue(field?.followUpQuestions[0], "")
            }, 100)
          }
        }
      }
    }

    if (field?.name === "height") {
      field.options = heightAndWeightOptions.height
    }

    if (field?.name === "weight") {
      field.options = heightAndWeightOptions.weight
    }

    return field
  })

  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  let medensureServices = []
  if (isBrowser())
    medensureServices = JSON.parse(sessionStorage.getItem("medensureServices"))

  const handleSubmit = (values) => {
    refillsDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...refillsState,
        updatedPrescription: {
          ...refillsState.updatedPrescription,
          ...values,
        },
      },
    })
    navigate("/medensure/refills/summary")
  }

  useEffect(() => {
    refillsDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      path="/medensure/refills"
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        <Formik
          enableReinitialize
          initialValues={{
            ...generateInitialValues({ fields: formFields }),
            ...refillsState.updatedPrescription,
            uploadPrescription: [...(refillsState.documents || [])],
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid, submitCount, errors }) => (
            <Form>
              {sectionFormFields.map((section) => {
                return (
                  <Section
                    title={
                      sectionFormFields.length === 1 ? "" : section?.section
                    }
                    subtitle={section?.subtitle || ""}
                    id={section?.sectionId || ""}
                    className="mt-1 mb-3"
                  >
                    {section?.message && (
                      <Message color={section?.messageColor || "light"}>
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: section?.message,
                          }}
                        />
                      </Message>
                    )}{" "}
                    {section?.fields.map((field) => {
                      if (!field?.referenceAnswer) {
                        return (
                          <Fragment>
                            {generateFormField({
                              formFields: section?.fields,
                              formField: {
                                ...field,
                                disabled: !!field.disabled,
                              },
                              values,
                              setFieldValue,
                              context: {
                                refillsState,
                                refillsDispatch,
                              },
                              errors,
                              submitCount,
                            })}
                            {!!field?.addDividerAfterField && (
                              <hr className="has-background-light" />
                            )}
                          </Fragment>
                        )
                      }
                      return null
                    })}
                  </Section>
                )
              })}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <Message color="warning">
                After you review your summary and submit this form, we will
                redirect you to a page where you can view available teleconsult
                schedules and book an appointment that works for you.
              </Message>
              <ActionButtons
                back={{
                  label: "Back",
                  link: getBackPage(
                    medensureServices,
                    "Book a free teleconsult"
                  ),
                }}
                submit={{
                  label: "Summary",
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default TeleconsultForm
